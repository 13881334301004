import React, { useEffect, useContext } from 'react';
import { UserData } from 'react-oidc';
import { styled } from '@material-ui/core';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import { useCookieSessionContext } from './components/SessionProvider';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useSearchToken from './hooks/useSearchToken';
import useTssVideoToken from './hooks/useTssVideoToken/useTssVideoToken';
import { listenCookieChange, readCookie, COOKIE_KEYS } from './utils/cookies';
import { userManager } from '.';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

export default function App() {
  const roomState = useRoomState();
  const { setInSession } = useCookieSessionContext();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const reactoidcAuthContext = useContext(UserData);

  useSearchToken(); //athena model, token in search string
  useTssVideoToken(); //gemini model, use IDS and get token from endpoint

  useEffect(() => {
    if (roomState === 'disconnected') {
      setInSession(false);
    } else {
      setInSession(true);
    }
  }, [roomState, setInSession]);

  useEffect(() => {
    const intervalId = listenCookieChange(cookieValues => {
      const newCookieTimeout = readCookie(COOKIE_KEYS.iesoCookieTimeout);
      const oldCookieTimeout = readCookie(COOKIE_KEYS.iesoCookieTimeout, cookieValues.oldValue);

      if (!newCookieTimeout && newCookieTimeout !== oldCookieTimeout) {
        userManager.revokeAccessToken();
        userManager.removeUser();
        userManager.signoutRedirect();
        window.location.reload();
      }
    });
    return () => clearInterval(intervalId);
  }, [reactoidcAuthContext]);

  return (
    <Container style={{ height }}>
      <MenuBar />
      <Main>
        {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
        <Controls />
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
