import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeAuthenticator, makeUserManager, Callback } from 'react-oidc';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import generateConnectionOptions from './utils/generateConnectionOptions/generateConnectionOptions';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import { CookieSessionProvider } from './components/SessionProvider';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { LOCAL_STORAGE_KEYS } from './utils/localstorage';

const VideoApp = (props: any) => {
  const { error, setError, settings } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);

  return (
    <UnsupportedBrowserWarning>
      <CookieSessionProvider>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <App {...props} />
        </VideoProvider>
      </CookieSessionProvider>
    </UnsupportedBrowserWarning>
  );
};

export const userManager = makeUserManager(resolveOidcConfiguration() ?? {});

//adds IDS auth with a callback and silent_callback
const AppWithAuth = makeAuthenticator({
  userManager: userManager,
  signinArgs: {
    state: {
      location: document.location,
    },
  },
})(props => (
  <React.Fragment>
    <PrivateRoute exact path="/">
      <VideoApp {...props} />
    </PrivateRoute>
    <PrivateRoute
      path={['/Appointment/:Role/:AppointmentGuid/:Panel', '/Appointment/:Role/:AppointmentGuid', '/room/:URLRoomName']}
    >
      <VideoApp {...props} />
    </PrivateRoute>
  </React.Fragment>
));

const Routes = () => {
  return (
    <Router>
      <AppStateProvider>
        <Switch>
          <Route
            path="/callback"
            render={routeProps => (
              <Callback
                onSuccess={user => {
                  // `user.state` will reflect the state that was passed in via signinArgs.
                  const storedUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.appointmentUrl);
                  const storedLocation = user?.state?.location || window.location;
                  if (storedLocation.pathname === '/' && storedUrl) {
                    const url = new URL(storedUrl);
                    routeProps.history.push(url.pathname);
                  } else {
                    routeProps.history.push(storedLocation);
                  }
                }}
                onError={(...err) => {
                  console.error(err);
                }}
                userManager={userManager}
              />
            )}
          />
          <AppWithAuth />
        </Switch>
      </AppStateProvider>
    </Router>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Routes />
  </MuiThemeProvider>,
  document.getElementById('root')
);

function resolveOidcConfiguration() {
  const iesoConfig = (global as any).__ieso_config;

  // Nasty `includes()` instead of using URLSearchParams because it's not supported by all browsers we support in Core, let
  // `UnsupportedBrowserWarning` do its job.
  const loginForIds = window.location.search.includes('platform=ids');

  if (loginForIds) {
    sessionStorage.setItem('platform', 'ids');
  }

  return loginForIds || sessionStorage.getItem('platform') === 'ids'
    ? iesoConfig?.userConfigClassic
    : iesoConfig?.userConfigPex;
}
