import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { COOKIE_KEYS, createCookie } from '../../utils/cookies';

interface ISessionContext {
  inSession: boolean;
  setInSession: (arg: boolean) => void;
}

const CookieSessionContext = createContext<ISessionContext>({ inSession: false, setInSession: () => {} });

export const useCookieSessionContext = () => {
  return useContext(CookieSessionContext);
};

export const CookieSessionProvider: React.FC = ({ children }) => {
  const [inSession, setInSession] = useState(false);
  const timerId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (inSession) {
      timerId.current = setInterval(() => {
        createCookie(COOKIE_KEYS.iesoCookieTimeout, Date.now());
      }, 1000);
    } else {
      timerId.current && clearInterval(timerId.current);
    }

    return () => {
      timerId.current && clearInterval(timerId.current);
    };
  }, [inSession]);

  return <CookieSessionContext.Provider value={{ inSession, setInSession }}>{children}</CookieSessionContext.Provider>;
};
