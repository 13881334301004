import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserData } from 'react-oidc';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import { useAppState } from '../../state';
import useVideoContext from '../useVideoContext/useVideoContext';

// eslint-disable-next-line no-useless-escape
const guidRe = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;

export default function useTssVideoToken() {
  const { setUserName, setRoomName, setProvidedToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks, localTracks } = useVideoContext();
  const [connecting, setConnecting] = useState(false);
  const [videoToken, setVideoToken] = useState<string | null>(null);

  const params = useParams();
  const reactoidcAuthContext = useContext(UserData);
  const access_token = reactoidcAuthContext?.user?.access_token;
  const appointmentGuid = (params as any)?.AppointmentGuid;

  useEffect(() => {
    if (!appointmentGuid || !guidRe.test(appointmentGuid)) {
      return;
    }
    if (!access_token) {
      return;
    }
    axios({
      method: 'put',
      url: `${(global as any).__ieso_config.tss_url}/therapy/v1/sessions/${appointmentGuid}/videoroom`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(result => {
        setVideoToken(result.data?.token);
        setProvidedToken(result.data?.token);
      })
      .catch(err => {
        //failures
        console.log(err);
      });
  }, [access_token, appointmentGuid, setProvidedToken]);

  const isReadyToConnect = (!isAcquiringLocalTracks && localTracks.length && !isConnecting) || isFetching || connecting;

  useEffect(() => {
    if (!(appointmentGuid && access_token && isReadyToConnect && videoToken)) {
      return;
    }

    const userName = reactoidcAuthContext?.user?.profile?.name;
    setUserName(userName || '@');

    const videoInfo = jwt.decode(videoToken) as any;
    const roomName = videoInfo?.grants?.video?.room;
    if (roomName) setRoomName(roomName);

    if (videoToken && !isAcquiringLocalTracks) {
      setConnecting(true);
      connect(videoToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToConnect, videoToken]);

  return { appointmentGuid: (params as any).AppointmentGuid, videoToken, connecting };
}
